<template>
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          <translate>Notifications Settings</translate>
        </h4>
      </div>

      <div class="card-body">
        <div class="row">
          <FormGroupCheckbox
            class="col-md-6"
            :label="
              $gettext('Send me an email when there is a new application')
            "
            field="should_receive_application_alert"
            v-model="payload.should_receive_application_alert"
          />
        </div>
        <div
          class="row"
          v-for="filter in payload.email_applications_filters"
          :key="filter.name"
        >
          <FormGroupCheckbox
            class="col-md-3"
            :label="filter.field.label"
            v-model="filter.checked"
            :disabled="!payload.should_receive_application_alert"
          />
          <div class="col-md-2">
            <multiselect
              :disabled="
                !filter.checked || !payload.should_receive_application_alert
              "
              label="label"
              track-by="value"
              :options="operatorOptions"
              v-model="filter.operator"
              :show-labels="false"
            ></multiselect>
          </div>
          <div
            class="col-md-7 d-flex justify-content-between align-items-center"
          >
            <multiselect
              :loading="false"
              :disabled="!filter.checked"
              :options="filter.options"
              label="option_name"
              :internal-search="true"
              v-model="filter.value"
              :multiple="true"
              track-by="option_id"
              @search-change="handleSearchChange($event, filter)"
              :show-labels="false"
              :show-no-options="false"
              :placeholder="
                !filter.disabledInput ? $gettext('Type to Search...') : ''
              "
            >
              <span slot="noResult"><translate>No results</translate></span>
            </multiselect>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-button @click="handleUpdateFilter" variant="info"
              ><translate>Update</translate></b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FormGroupCheckbox from "@/components/FormGroups/FormGroupCheckbox.vue";
import Multiselect from "vue-multiselect";
import { Operator } from "@/utils/filters";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { Multiselect, FormGroupCheckbox },
  created() {
    this.payload.email_applications_filters = this.payload.email_applications_filters.map(
      payloadFilter => {
        const currentFilter = this.emailApplicationsFilters.find(filter => {
          return payloadFilter.field.name === filter.field;
        });

        if (currentFilter) {
          payloadFilter.checked = true;
          payloadFilter.value = currentFilter.value;
          payloadFilter.operator =
            currentFilter.operator === "=" ? Operator.Equal : Operator.NotEqual;
        }
        return payloadFilter;
      }
    );
    this.payload.should_receive_application_alert = this.user.should_receive_application_alert;
  },
  data() {
    return {
      payload: {
        should_receive_application_alert: false,
        email_applications_filters: [
          {
            checked: false,
            field: { name: "country", label: this.$gettext("Country") },
            operator: Operator.Equal,
            value: [],
            options: [],
            optionsEndpoint: "ats/filter-options/country"
          },
          {
            checked: false,
            field: { name: "company", label: this.$gettext("Company") },
            operator: Operator.Equal,
            value: [],
            optionsEndpoint: "ats/filter-options/user-companies",
            options: []
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters("me", ["user", "emailApplicationsFilters"]),
    operatorOptions() {
      return Object.values(Operator).filter(({ value }) =>
        ["=", "!="].includes(value)
      );
    },
    parsedPayload() {
      return {
        ...this.payload,
        email_applications_filters: this.payload.email_applications_filters
          .filter(
            ({ checked }) =>
              checked === true && this.payload.should_receive_application_alert
          )
          .map(filter => {
            return {
              field: filter.field.name,
              operator: filter.operator.value,
              value: filter.value
            };
          })
      };
    }
  },
  methods: {
    ...mapActions("me", ["updateEmailApplicationFilter"]),
    ...mapActions("form", ["submit"]),
    handleUpdateFilter() {
      this.submit(() => this.updateEmailApplicationFilter(this.parsedPayload))
        .then(() => {
          this.$toast.open({
            message: "Done!",
            type: "success"
          });
        })
        .catch(({ response }) => {
          if (response.status === 422) {
            this.$toast.open({
              message: this.$gettext(
                "Please select at least one value for each checked filters"
              ),
              type: "error"
            });
          }
        });
    },
    handleSearchChange(q, filter) {
      const url = `${filter.optionsEndpoint}?&q=${q}`;
      this.$http.get(url).then(({ data }) => (filter.options = data));
    }
  }
};
</script>
