<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title"><translate>Basic information</translate></h4>
        </div>

        <div class="card-body">
          <form @submit.prevent="handleUpdateProfile">
            <div class="row">
              <FormInput
                class="col-md-6"
                v-model="userPayload.first_name"
                :label="$gettext('First Name')"
                field="first_name"
                required
              />
              <FormInput
                class="col-md-6"
                :label="$gettext('Last Name')"
                v-model="userPayload.last_name"
                field="last_name"
                required
              />
              <FormInput
                class="col-md-6"
                :label="$gettext('Email')"
                v-model="userPayload.email"
                field="email"
                required
              />
              <FormInput
                class="col-md-6"
                :label="$gettext('Phone number')"
                v-model="userPayload.phone_number"
                field="phone_number"
              />
            </div>
            <div class="row d-flex align-items-center">
              <FormGroupMultiselect
                class="col-md-6"
                :options="languages"
                v-model="userPayload.language"
                :label="$gettext('Preferred language of communication')"
                field="language"
                :searchable="false"
              />
            </div>
            <div class="form-group">
              <SubmitButton
                :label="$gettext('Update')"
                class="btn btn-primary"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title"><translate>Change password</translate></h4>
        </div>

        <div class="card-body">
          <form @submit.prevent="handleChangePassword">
            <div class="row">
              <FormInput
                class="col-md-6"
                type="password"
                v-model="password.current_password"
                :label="$gettext('Current password')"
                field="current_password"
                required
              />
            </div>
            <div class="row">
              <FormInput
                class="col-md-6"
                type="password"
                v-model="password.password"
                :label="$gettext('New password')"
                field="password"
                required
              />

              <FormInput
                class="col-md-6"
                type="password"
                v-model="password.password_confirmation"
                :label="$gettext('Password confirmation')"
                field="password_confirmation"
              />
            </div>

            <div class="form-group">
              <SubmitButton
                :label="$gettext('Update')"
                class="btn btn-primary"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
    <Notifications />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FormInput from "@/components/FormGroups/FormInput";
import SubmitButton from "@/components/SubmitButton";
import FormGroupMultiselect from "@/components/FormGroups/FormGroupMultiselect";
import Notifications from "@/views/Account/Notifications.vue";

export default {
  components: {
    Notifications,
    FormGroupMultiselect,
    SubmitButton,
    FormInput
  },
  created() {
    this.userPayload = Object.assign(this.userPayload, this.user);
    this.userPayload.language = this.languages.find(
      lang => lang.value === this.user.language
    );
  },
  data: () => ({
    userPayload: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      language: ""
    },
    password: {
      current_password: "",
      password: "",
      password_confirmation: ""
    }
  }),
  computed: {
    ...mapGetters("me", ["user"]),
    languages() {
      return [
        {
          value: "fr",
          label: this.$gettext("French")
        },
        {
          value: "en",
          label: this.$gettext("English")
        }
      ];
    }
  },
  methods: {
    ...mapActions("me", ["updateProfile", "changePassword"]),
    ...mapActions("form", ["submit"]),
    handleUpdateProfile() {
      this.submit(() =>
        this.updateProfile({
          ...this.userPayload,
          language: this.userPayload.language.value
        })
      ).then(() => {
        this.$toast.open({
          message: "Done!",
          type: "success"
        });
      });
    },
    handleChangePassword() {
      this.submit(() => this.changePassword(this.password)).then(() => {
        this.$toast.open({
          message: "Done!",
          type: "success"
        });
      });
    }
  }
};
</script>
